<header>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <img [src]="logo" [alt]="'GENERIC.LOGO' | translate" class="ebook-logo" />
    </div>
    <div class="d-flex p-1">
      @if(isShowAssignButton()) {
      <div class="col d-flex p-1">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="onClickAssignButton()">
          {{ "GENERIC.BUTTONS.ASSIGN" | translate }}
        </button>
      </div>
      } @if(isShowUnpairButton()) {
      <div class="col p-1">
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          (click)="openUnpairModal()">
          {{ "GENERIC.BUTTONS.UNPAIR" | translate }}
        </button>
      </div>
      }
      <div class="col p-1">
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          (click)="onExit()">
          {{ "GENERIC.BUTTONS.EXIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</header>

<!-- Unpair Modal -->
<ng-template #unpairModal>
  <up-bs-modal
    [config]="unpairModalConfig"
    (modalEvent)="handleModalEvent.bind(this)($event)">
    <div>
      @if(isAssigned()) {
      <p
        data-automation-id="ins-unpair-text"
        [innerHTML]="
          'INSTRUCTOR.UNPAIR_MODAL.TEXT_ASSIGNED'
            | translate : { ebookName: ebookName() }
        "></p>
      } @else {
      <p
        data-automation-id="ins-unpair-text"
        [innerHTML]="
          'INSTRUCTOR.UNPAIR_MODAL.TEXT'
            | translate : { ebookName: ebookName() }
        "></p>

      }
    </div>
  </up-bs-modal>
</ng-template>
