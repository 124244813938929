import { ltiParams } from 'up/lib/interfaces/config.interface';

export interface LtiParamsState {
  ltiParams: ltiParams;
}

export const initialLtiParamsState: LtiParamsState = {
  ltiParams: {
    sectionId: 0,
    isbn: '',
    role: '',
    lmsInstitutionName: '',
    sectionXid: '',
    lmsName: '',
    userXid: '',
    organizationXid: '',
    customLineItemUrl: '',
    resourceLinkId: '',
    goldyAction: '',
    ltiaAction: '',
    isSectionPaired: false,
    platform: '',
    launchId: '',
    ebookId: '',
    attemptId: 0,
    attemptNo: 0,
    assignmentId: 0,
    epubcfi: '',
    assignmentTitle: '',
    isCourseCopy: false,
    mode: '',
    lmsLocale: '',
    isLockedCourse: false,
    isTemplateCourse: false,
    remainingAttempts: 0,
    latestAttemptNo: 0,
    latestAttemptStatus: '',
    maxAttempts: 0,
    hasAssignmentCompleted: false,
    isCourseNavigationLaunch: false,
    isNeoLaunch: false,
    productDisciplineName: '',
    productXid: '',
    userEmailId: '',
    productTitle: '',
    productAuthor: '',
    userId: 0,
    assigned: false,
  },
};
